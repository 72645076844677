import { inject } from "vue";
import type { AxiosInstance } from "axios";

export function useAxiosInstance() {
    // We know this axios instance is always available since it's provided
    // in `main.ts`, so the value can be appropriately casted to `AxiosInstance`.
    const axios = inject("$axios") as AxiosInstance;

    return { axios };
}
