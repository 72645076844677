import { defineStore } from "pinia";
import type { SupportedLanguage } from "../types";

interface Localization {
    displayLanguage: SupportedLanguage;
    pressureUnit: string;
    temperatureUnit: string;
    distanceSmallUnit: string;
}

export const useLocalizationStore = defineStore({
    id: "LocalizationStore",
    state: (): Localization => {
        if (
            JSON.parse(localStorage.getItem("user-config") || "{}").pressure_unit &&
            JSON.parse(localStorage.getItem("user-config") || "{}").temperature_unit
        ) {
            return {
                displayLanguage: "en_GB",
                pressureUnit: JSON.parse(localStorage.getItem("user-config") || "{}").pressure_unit,
                temperatureUnit: JSON.parse(localStorage.getItem("user-config") || "{}").temperature_unit,
                distanceSmallUnit: "mm",
            };
        }

        return {
            displayLanguage: "en_GB",
            pressureUnit: "PSI",
            temperatureUnit: "CELSIUS",
            distanceSmallUnit: "mm",
        };
    },
});
