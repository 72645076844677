import { useAxiosInstance } from "../composables/useAxiosInstance";
import { defineStore } from "pinia";
import { useAxios } from "@vueuse/integrations/useAxios";

import type { IntegrationId } from "@/constants";
import type { AlertSubscription, UserPermission } from "@/types";

interface Integration {
    integration_id: IntegrationId;
    data: typeof Object;
}

interface User {
    username: string;
    organization: {
        name: string;
        show_footer: boolean;
        integrations: Integration[];
    };
    organization_name: string;
    subscribed_to: AlertSubscription[];
    email: string;
    firstname: string;
    lastname: string;
    language: "en_GB" | "it" | "de" | "fr" | "tr";
    pressure_unit: "PSI" | "BAR";
    temperature_unit: "CELSIUS" | "FAHRENHEIT";
    phone_number: string | null;
    is_admin: boolean;
    permissions: UserPermission[];
}

export const useUserStore = defineStore("user", () => {
    const { axios } = useAxiosInstance();

    const { data: user, isLoading, isFinished } = useAxios<User>("/tyrewatch/me", axios);

    const hasIntegration = (integration_id: IntegrationId): Integration | undefined => {
        return user.value?.organization.integrations.find(
            (integration) => integration.integration_id === integration_id
        );
    };

    const hasPermission = (permission: string) => {
        return (
            user.value?.permissions.some((element: UserPermission) => element.action === permission) ||
            user.value?.permissions.length === 0 // Catch legacy users with no permissions
        );
    };

    return { user, isLoading, isFinished, hasIntegration, hasPermission };
});
