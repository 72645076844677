{
    "general": {
        "submit": "Enviar",
        "imei": "IMEI (Identificação Internacional de Equipamento Móvel)",
        "powered-by": "Desenvolvido por",
        "access_level": "Nível de Acesso",
        "managed_by": "Gerenciado por {value}",
        "clear-screen": "Limpar Tela",
        "reconnect": "Reconectar",
        "general": "Geral",
        "alerts": "Alertas",
        "sim_card": "Cartão SIM",
        "rl-automotive": "RL Automotive",
        "serial_number": "Número de Série",
        "you": "Você",
        "remote": "Remoto",
        "contacts": "Contatos",
        "address": "Endereço",
        "readings": "Sem leituras | {count} Leitura | {count} Leituras",
        "provider": "Fornecedor",
        "show_more": "Mostrar mais",
        "next": "Próximo",
        "previous": "Anterior",
        "checklist_faults": "{ count } Falha identificada na lista de verificação | { count }  Falhas identificadas na lista de verificação",
        "show_less": "Mostrar menos",
        "checksum_calculator": "Calculadora de soma de verificação",
        "checksum_calculator_prompt": "Adicionando um sensor ao veículo? Utilizar {0}",
        "date": "Data",
        "role": "Função",
        "sort_by": "Ordenar por",
        "alert_low_pressure": "Alerta Crítico (Pressão Baixa)",
        "alert_high_temperature": "Alerta (Temperatura Alta)",
        "warning": "Aviso",
        "warnings": "Sem Avisos | { count } Aviso| { count } Aviso",
        "maintenance": "Manutenção",
        "unassigned": "Não Atribuído",
        "stale": "Em espera",
        "print": "Imprimir",
        "update_profile": "Atualizar Perfil",
        "signed_in_as": "Conectado como",
        "vin": "Tensão de entrada",
        "all": "Todos",
        "no_alert": "Ok",
        "sensor_id": "ID do Sensor",
        "telematics_units": "Unidades de Telemetria",
        "start": "Iniciar",
        "all_time": "Tempo de conclusão",
        "last_seen": "Visto por último",
        "confirm": "Confirmar",
        "create": "Criar",
        "from": "De",
        "admin": "Administrador",
        "member": "Membro",
        "last_time_seen": "Última vez visto",
        "last_time_seen_desc": "Última vez visto (decrescente)",
        "include_inactive": "Incluir Inativos",
        "end": "Fim",
        "home": "Início",
        "edit": "Editar",
        "reports": "Relatórios",
        "kilometers": "Quilômetros",
        "distance": "Distância",
        "organisation": "Organização",
        "organisations": "Organizações",
        "events": "Eventos",
        "changelog": "Registro de Alterações",
        "environmental": "Ambiental",
        "no_data": "Nenhum dado recebido",
        "notes": "Notas",
        "update": "Atualização",
        "none": "Nenhum",
        "unknown": "Desconhecido",
        "not_set": "Não Definido",
        "username": "Usuário",
        "password": "Senha",
        "fleet": "Frota",
        "fleet_name": "Nome da Frota",
        "registration": "Registro",
        "vehicles": "Veículos",
        "settings": "Configurações",
        "cancel": "Cancelar",
        "id": "ID",
        "close": "Fechar",
        "select_one": "Por favor, selecione um",
        "email": "Email",
        "phone_number": "Número de Telefone",
        "celcius": "Celcius",
        "coming_soon": "Em Breve",
        "farenheit": "Farenheit",
        "search": "Pesquisar",
        "dismiss": "Descartar",
        "confirm_delete": "Tem certeza de que deseja excluir {value}?",
        "name": "Nome",
        "any": "Qualquer",
        "delete": "Excluir",
        "language": "Idioma",
        "type": "Tipo",
        "yes": "Sim",
        "no": "Não",
        "archived": "Arquivado",
        "pressure": "Pressão",
        "cold_pressure": "Pressão á frio",
        "iccid": "ICCID",
        "emails": "Email1, Email2, ....",
        "grams": "gramas",
        "request_quote": "Solicitar citação",
        "members": "Membros",
        "url": "URL",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "timestamp": "Data/Hora",
        "temperature": "Temperatura",
        "no_results": "Nenhum Resultado encontrado",
        "no_results_subheading": "Nenhum resultado encontrado que corresponda aos critérios de pesquisa",
        "refine_search": "Por favor, refine a sua busca",
        "refine_search_subheading": "Pesquise pelo número de registro de um veículo ou selecione uma Frota/Depósito específico",
        "created_on": "Criado em",
        "created_on_date": "Criado em {date}",
        "registration_id": "Registro/ID",
        "position": "Posição",
        "vehicle": "Veículo",
        "download": "Download",
        "wheel_position": "Posiçao da Roda",
        "select_your_option": "Selecione a sua opção",
        "are_you_sure": "Tem certeza?"
    },
    "organisation-hierachy": {
        "create-organisation": "Criar Organização",
        "create-organisation-modal-subtitle": "Organizações são usadas para fornecer separação entre clientes e usuários. Criar uma suborganização oferece a capacidade de limitar o acesso aos veículos."
    },
    "fleet-list": {
        "tyre-status": "Status do Pneu"
    },
    "fleet_pmi_report": {
        "heading": "Dados de inspeção para pré-manutenção. Isso pode levar algum tempo para carregar.",
        "wheel_position": "Posição da Roda",
        "alert_type": "Tipo de alerta",
        "pressure": "Pressão (PSI)",
        "cold_pressure": "Pressão á frio (PSI)",
        "recommended_pressure": "Pressão recomendada (PSI)",
        "no_issues": "Sem problemas",
        "no_issues_prompt": "Não houve problemas registrados no período selecionado",
        "inflate_difference": "+/- Inflar (PSI)",
        "total_alerts": "Alertas",
        "total_warnings": "Avisos",
        "total_maintenance": "Recomendações de manutenção",
        "total_over_inflations": "Sobreinflações",
        "total_tyres_checked": "Pneus Verificados",
        "total_vehicles_checked": "Veículos Verificados",
        "total_vehicles": "Veículos no total",
        "percentage_underinflated": "Porcentagem de pneus subinflados",
        "percentage_overinflated": "Porcentagem de pneus sobreinflados"
    },
    "fleet_km_report": {
        "heading": "Dados de velocidade e distância. Isso pode levar algum tempo para carregar",
        "total_distance_travelled": "Distância Total Percorrida (km)",
        "average_speed": "Velocidade Média (km/h)"
    },
    "fleet_carbon_report": {
        "heading": "Dados de emissões. Isso pode levar algum tempo para carregar",
        "co2_emissions": "Emissões de CO2(g/km)",
        "co2_emitted": "CO2 Emitido (g)"
    },
    "services": {
        "1": "Latitude",
        "2": "Longitude",
        "3": "Altitude",
        "4": "Pressão do Pneu 1A",
        "5": "Pressão do Pneu 1B",
        "6": "Pressão do Pneu 1C",
        "7": "Pressão do Pneu 1D",
        "8": "Pressão do Pneu 2A",
        "9": "Pressão do Pneu 2B",
        "10": "Pressão do Pneu 2C",
        "11": "Pressão do Pneu 2D",
        "12": "Pressão do Pneu 3A",
        "13": "Pressão do Pneu 3B",
        "14": "Pressão do Pneu 3C",
        "15": "Pressão do Pneu 3D",
        "16": "Pressão do Pneu 4A",
        "17": "Pressão do Pneu 4B",
        "18": "Pressão do Pneu 4C",
        "19": "Pressão do Pneu 4D",
        "20": "Pressão do Pneu 5A",
        "21": "Pressão do Pneu 5B",
        "22": "Pressão do Pneu 5C",
        "23": "Pressão do Pneu 5D",
        "24": "Pressão do Pneu 6A",
        "25": "Pressão do Pneu 6B",
        "26": "Pressão do Pneu 6C",
        "27": "Pressão do Pneu 6D",
        "28": "Odômetro",
        "29": "Velocidade",
        "30": "Nível de Combustível (Absoluto)",
        "31": "Aviso Nível de Combustível",
        "32": "Aviso Pastilha de Freio",
        "33": "Aviso de nível do fluido de arrefecimento do motor",
        "34": "Aviso de temperatura do fluido de arrefecimento do motor",
        "35": "Aviso de nível do óleo do motor",
        "36": "Aviso de bateria",
        "37": "Temperatura do fluido de arrefecimento do motor",
        "40": "Nível de Combustível",
        "41": "Nível de Arla-32",
        "42": "Nível do Óleo do motor",
        "46": "Intervalo de manutenção"
    },
    "vehicle-emissions": {
        "fuel-used": "Combustível usado (litros)",
        "carbon-emitted": "CO2 emitido (kg)",
        "emissions-since": "Emissões desde {0}",
        "distance-travelled": "Distância Percorrida (km)"
    },
    "organisation-base-card": {
        "show-users": "Mostrar usuários",
        "hide-users": "Ocultar usuários",
        "your-organisation": "Sua organização",
        "no-login": "Nenhum login registrado",
        "no-users": "Nenhum usuário nesta organização",
        "last-seen": "Visto por último {0}"
    },
    "organisation_settings": {
        "no_profile_picture": "Nenhuma foto de perfil",
        "setup_heading": "Configuração Avançada da Organização",
        "setup_body": "Personalização avançada da organização, incluindo logos e personalização de tema, está disponível mediante solicitação à RL Automotive.",
        "successfully_updates": "Perfil atualizado com sucesso",
        "alerts_subheading": "Veículos aos quais {username} está inscrito",
        "no_vehicles_content": "Este usuário não pertence a nenhum veículo",
        "view_website": "Ver website"
    },
    "errors": {
        "generic_unexpected_error": "Um erro inesperado ocorreu",
        "failed_to_load_alert": "Falha em carregar a página de veículos. Por favor tente novamente mais tarde",
        "failed_to_update_telematics_unit": "Falha em atualizar a unidade de telemetria. Por favor tente novamente mais tarde",
        "failed_to_load_telematics_units": "Falha em carregar a unidade de telemetria. Por favor tente novamente mais tarde",
        "failed_to_create_telematics_unit": "Falha em criar a unidade de telemetria, por favor verifique a configuração e tente novamente",
        "failed_to_delete_telematics_unit": "Falha em apagar a unidade de telemetria. Por favor tente novamente mais tarde!",
        "update_profile_error": "Um erro inesperado ocorreu ao atualizar o perfil",
        "delete_user_error": "Falha em apagar usuário"
    },
    "colours": {
        "black": "Preto",
        "blue": "Azul",
        "green": "Verde",
        "yellow": "Amarelo",
        "orange": "Laranja",
        "red": "Vermelho"
    },
    "pages": {
        "manage_reports": "Gerenciar Relatórios",
        "manage_sim": "Gerenciar Cartões SIM",
        "edit_sim": "Editar Cartão SIM"
    },
    "navbar": {
        "kit_menu": "Abrir menu KIT",
        "user_menu": "Abrir menu de usuário",
        "vehicles": "Veículos",
        "fleets": "Frotas",
        "sensors": "Sensores",
        "reports": "Relatórios",
        "kit": "Kit",
        "sim_cards": "Cartões SIM",
        "telematics_boxes": "Unidades de Telemetria",
        "settings": "Configurações da Conta",
        "logout": "Desconectar"
    },
    "landing": {
        "quick_actions": "Ações Rápidas",
        "add_vehicle": "Adicionar Veículos",
        "add_vehicle_prompt": "Adicione um veículo para iniciar",
        "add_report": "Adicionar Relatórios",
        "add_report_prompt": "Configurar um relatório para obter atualização sobre suas frotas",
        "dashboard": "Painel de Controle",
        "sensors_on_vehicles": "Sensores ligados em veículos",
        "sensors_on_vehicles_prompt": "Sensores atribuídos a um veículo",
        "sensors_off_vehicles": "Sensores desligados em veículos",
        "sensors_off_vehicles_prompt": "Sensores não designados á um veículo. Estes sensores não serão capazes de coletar dados",
        "vehicles_prompt": "Veículos em sua organização e todas as organizações no qual você gerencia",
        "latest_changes": "Últimas mudanças"
    },
    "login": {
        "sign_in_message": "Faça login em sua conta",
        "sign_in": "Conectar",
        "incorrect_username_password": "Nome de usuário ou senha incorretos"
    },
    "vehicle_attributes": {
        "co2_emissions": "Emissões de CO2(g/km)",
        "type_approval": "Tipo de Aprovação",
        "euro_status": "Status PROCONVE"
    },
    "manage_vehicles": {
        "registration": "Registro",
        "imei": "IMEI (Identificação Internacional de Equipamento Móvel)",
        "fleet": "Frotas",
        "depot": "Depósitos",
        "show_deactivated": "Mostrar/Esconder veículos desativados",
        "show_archived": "Mostrar/Esconder veículos arquivados",
        "add_vehicle": "Adicionar Veículos",
        "total_vehicles": "Veículos",
        "details": "Detalhes do Pneu",
        "no_imei": "Sem IMEI (Identificação Internacional de Equipamento Móvel)",
        "alert_level": "Nível de Alerta",
        "open_map": "Mapa aberto",
        "no_results": "Nenhum Resultado encontrado",
        "no_results_subheading": "Nenhum veículo encontrado que corresponda aos critérios de pesquisa",
        "no_vehicles": "Nenhum Veículo",
        "no_vehicles_subheading": "Atualmente voce não possui nenhum veículo em sua organização. Clique em \"Adicionar Veículo\" para criar um",
        "sort_by": "Ordenar por",
        "tyre_maintenance": "Manutenção do Pneu"
    },
    "edit_vehicle": {
        "vehicles": "Veículos",
        "overview": "Visão geral",
        "logs": "Registros",
        "checklist": "Lista de Verificação",
        "mot_history": "Histórico de MOT"
    },
    "vehicle_overview": {
        "vehicle_alerts": "Alertas de email",
        "speed": "Velocidade",
        "engine_capacity": "Cilindrada",
        "revenue_weight": "Peso Bruto",
        "vehicle_alerts_description": "Receber alerta de email no caso deste veículo encontrar algum problema",
        "environmental_breakdown": "Desgaste Ambiental",
        "euro_rating_title": "Nenhuma classificação PROCONVE definida",
        "euro_rating_subtitle": "Por favor defina a classificação PROCONVE na configuração para visualizar esta seção",
        "add_config_error": "Por favor adicione este veículo uma configuração para iniciar a alocação de sensores",
        "archived": "Arquivado",
        "deactivated": "Desativado",
        "unarchived": "Desarquivado",
        "thresholds": "Limite",
        "below_recommended": "Abaixo do recomendado",
        "sr_enter_edit_mode": "Entrar no modo de edição dos detalhes do veículo.",
        "sr_exit_edit_mode": "Sair do modo de edição dos detalhes do veículo.",
        "type": "Tipo",
        "environmental": "Ambiental",
        "manufacturer": "Fabricante",
        "model": "Modelo",
        "chassis_type": "Tipo de Chassi",
        "fuel_type": "Tipo de combustível",
        "engine_type": "Tipo de Motor",
        "mot_due": "Data de conclusão do MOT",
        "fleet": "Frota",
        "depot": "Depósito",
        "imei": "IMEI (Identificação Internacional de Equipamento Móvel)",
        "configuration": "Configuração",
        "application": "Aplicação",
        "emergency_contact": "Contato de Emergência",
        "country": "País",
        "torque": "Torque",
        "axle": "Eixo",
        "sr_enter_edit_axle_mode": "Entrar no modo de edição de eixo",
        "sr_exit_edit_axle_mode": "Sair no modo de edição de eixo",
        "rim_size": "Medida do Aro",
        "tyre_size": "Medida do Pneu",
        "minimum_weight": "Peso Minímo",
        "maximum_weight": "Peso Máximo",
        "recommended_pressure": "Pressão recomendada ({unit})",
        "recommended_temperature": "Temperatura recomendada ({unit})",
        "maintenance_threshold": "Limite de Manutenção",
        "maintenance_threshold_description": "Limite que ativará o status de manutenção",
        "warning_threshold": "Limite de aviso",
        "warning_threshold_description": "Limite que ativará o status de aviso",
        "critical_threshold": "Limite Critíco",
        "critical_threshold_description": "Limite que ativará o status critíco",
        "maintenance_level": "Nível de Manutenção ({unit})",
        "warning_level": "Nível de Aviso ({unit})",
        "danger_level": "Nível de Perigo ({unit})",
        "submit": "Enviar",
        "sensors": "Sensores",
        "assigned_to": "Atribuído para",
        "last_seen": "Visto por último",
        "no_data": "Nenhum dado apresentado dentro intervalo de tempo selecionado",
        "please_check": "Por favor verificar os registros do veículo para mais informações",
        "temp_over_time": "Temperatura do Sensor/Pressão ao longo do tempo",
        "current_pressure": "Pressão Atual",
        "current_cold_pressure": "Pressão á frio Atual",
        "current_temp": "Temperatura Atual",
        "time_to_critical": "Tempo para Critíco",
        "recommended_tyre_make": "Fabricante recomendada de Pneu",
        "recommended_tyre_pattern": "Desenho recomendado de Pneu",
        "rim_type": "Tipo de Pneu",
        "tyre_condition": "Condição do Pneu",
        "new_tread_depth": "Nova Profundidade de Sulco",
        "gps_unavailable": "GPS Indisponível",
        "gps_fix_unavailable": "Sem Fixação de GPS",
        "current_location": "Localização Atual",
        "no_sensors": "Atualmente, não há sensores conectados a este veículo.",
        "kilometres_travelled": "Quilômetros percorridos",
        "kilometres_per_hour": "Quilômetros por hora",
        "kilometers_hour": "Quilômetros/hora",
        "schematic_view": "Visualização esquemática",
        "list_view": "Visualização em lista",
        "battery_unavailable": "Bateria Indisponível",
        "tyre_make": "Fabricante de Pneu",
        "tyre_pattern": "Desenho de Pneu",
        "estimated_co2": "Emissões Totais de CO2 (g/km)",
        "total_emissions": "Emissões Totais",
        "deactivate_vehicle": "Desativar Veículo",
        "spannered_off": "Modo de manutenção",
        "on": "Ligado",
        "off": "Desligado"
    },
    "attach_sensor_modal": {
        "checksum_calculator": "Soma de verificação",
        "create_title": "Criar novo sensor nesta posição",
        "id": "ID",
        "type": "Tipo",
        "create_sensor": "Criar e atribuir um sensor",
        "attach_title": "Selecionar um sensor para atribuição",
        "no_unassigned_sensors": "Nenhum sensor não atribuído encontrado",
        "no_unassigned_subheading": "Remover sensor de um veículo ou clicar no botão adicionar para cirar um novo",
        "show_assigned": "Exibir sensores conectados",
        "attach_sensor": "Anexar Sensor",
        "missing_sensor": "Nenhum sensor "
    },
    "delete_sensor_modal": {
        "delete_message": "Esta ação é irreversível e excluirá permanentemente este sensor da plataforma. Se você deseja desvincular o sensor para reutilização, por favor, cancele esta operação e selecione o botão de desvincular."
    },
    "vehicle_logs": {
        "limit": "Limite",
        "sort": "Ordenar",
        "newest": "Mais Recente",
        "oldest": "Mais Antigo",
        "filter": "Filtrar",
        "any": "Qualquer",
        "decode_success": "Sucesso de dedocificação",
        "decode_error": "Erro de decodificação",
        "configuration_error": "Erro de configuração",
        "since": "Desde",
        "now": "Agora",
        "reason": "Motivo",
        "sensor_id": "ID do sensor",
        "temperature": "Temperatura",
        "pressure": "Pressão",
        "speed": "Velocidade",
        "distance": "Distância",
        "no_logs": "Nenhum registro para exibir",
        "no_logs_subheading": "Por favor verifique a configuração do veículo.",
        "tpm_success_message": "Pacote TPM analisado com sucesso",
        "speed_dist_success_message": "Pacotes de velocidade e distância foram analisados com sucesso",
        "sensor_data_error_message": "Erro ao decodificar o pacote",
        "lantronix_data_error_message": "Por favor verifique a configuração da unidade de telemetria",
        "sensor_handshake_message": "Decodificação realizada com sucesso.",
        "sensor_error_message": "Algo deu errado (erro de sensor indefinido)",
        "unrecognized_external_sensor_pakcet": "Pacote de sensor externo não reconhecido recebido",
        "sensor_flag_set": "Aviso de status do sensor ativado, o sensor não transmitiu dados nos últimos 60 minutos."
    },
    "vehicle_checklist": {
        "checklist": "Lista de Verificação",
        "issued_by": "Emitido por",
        "created_at": "Criado em",
        "checklists_not_supported": "Atualmente este tipo de veículo não suporta Listas de verificação",
        "return_to_checklists": "Retornar para Listas de Verificação",
        "return_to_previous_recordings": "Retornar para registros anteriores",
        "add_checklist": "Adicionar Lista de Verificação",
        "reset": "Resetar",
        "brakes": "Freios",
        "horn_steering": "Buzina/Direção",
        "horn_and_steering": "Buzina/Direção",
        "lights": "Luzes",
        "mirror_glass": "Espelho/Vidros",
        "seat_belts": "Cintos de Segurança",
        "washers_wipers": "Limpadores de Para-brisa",
        "battery": "Bateria",
        "fluids_fuel_oil": "Fluidos/Combustível/Òleo",
        "bodywork_doors": "Carroceria/Portas",
        "bodywork_and_doors": "Carroceria/Portas",
        "exhaust": "Escapamento",
        "tyres_wheels": "Pneus/Rodas",
        "tyres_and_wheels": "Pneus/Rodas",
        "load": "Carga",
        "towbar_taillift": "Engate de reboque/Elevação traseira",
        "towbar_or_tail_lift": "Engate de reboque/Elevação traseira",
        "van_check": "Verificação de Van",
        "previous_recordings": "Gravações Anteriores",
        "faults": "Falhas",
        "no_recordings": "Não há gravações anteriores para este veículo",
        "mirrors_cameras_and_glass": "Espelhos Camêras e Vidros",
        "mirrors_and_glass": "Espelhos Camêras e Vidros",
        "windscreen_wipers_and_washers": "Limpadores e Lavadores de Para-brisa",
        "washers_and_wipers": "Limpadores e Lavadores de Para-brisa",
        "warning_lights": "Lanternas de aviso",
        "steering_and_electronic_stability_control": "Controle Eletrônico de Estabilidade e Direção",
        "horn": "Buzina",
        "breaks_and_air_build": "Freios",
        "height_marker": "Marcador de Altura",
        "seat_belts_and_cab_interior": "Cintos de Segurança e Interior de Cabine",
        "security_and_condition_of_cab_doors_steps": "Segurança e Condição das Portas da Cabine e Degraus",
        "brake_lines": "Linhas de Freio",
        "electrical_connections": "Conexões Elétricas",
        "coupling_security": "Segurança de Acoplamento",
        "load_security": "Segurança da Carga",
        "number_plate": "Placa de Matrícula",
        "reflectors_and_lights": "Refletores e Luzes",
        "markers": "Marcadores",
        "ancillary_equipment": "Equipamento Auxiliar",
        "lights_indicators_and_side_repeaters": "Indicadores de luz e repetidores laterais",
        "fuel_oil_leaks": "Vazamentos de Óleo e Combustível",
        "security_and_condition_of_body_wings": "Condição e segurança das asas da carroceria",
        "battery_security_and_condition": "Segurança e Condição da Bateria",
        "diesel_exhaust_fluid_adblue": "Fluido do escapamento Diesel",
        "excessive_engine_exhaust_smoke": "Fumaça do escapamento do motor excessiva",
        "aleternative_fuel_systems_and_isolation": "Sistemas de Combustíveis Alternativos e Isolamento",
        "spray_suppression": "Suspensão de Spray",
        "tyres_and_wheel_fixing": "Fixação das Rodas e dos Pneus",
        "high_voltage_emergency_cut_off_switch": "Interruptor de Corte de Emergência de Alta Voltagem",
        "cab_mirrors_seatbelts_glass": "Cabine, Espelhos, Cintos de Segurança e Vidros",
        "cab_mirror_seat_belt_and_glass": "Cabine, Espelhos, Cintos de Segurança e Vidros",
        "horn_and_gauges": "Buzinas e mostradores",
        "turn_signals": "Sinais de Direção",
        "backup_lights_and_alarm": "Luzes de Ré e Alarme",
        "fire_extinguisher_condition": "Condição do Extintor de Incêndio",
        "windshield_wipers_and_fluid": "Limpadores de Para-brisa e Fluido",
        "fuel_levels": "Niveis de combustível",
        "engine_oil": "Óleo do motor",
        "transmission_fluid": "Fluido da caixa de transmissão",
        "brake_fluid": "Fluido de Freio",
        "cooling_system_fluid": "Fluido do sistema de Arrefecimento",
        "oil_leak_lube": "Vazamento de Óleo/Lubrificante",
        "brake_condition": "Condições dos Freio (Serviço dinâmica, estacionamento, etc.)",
        "tyres_or_tracks": "Pneus ou esteiras (pressão, danos, etc.)",
        "tires_or_tracks": "Pneus ou esteiras (pressão, danos, etc.)",
        "hydraulic_oil": "Óleo Hidráulico",
        "hose_condition": "Condição da mangueira",
        "coupling_devices_and_connectors": "Dispositivos de Acoplamento e Conectores",
        "exhaust_system": "Sistema de Exaustão",
        "blade_bloom_ripper": "Condição da Lâmina/Pá/Ripper",
        "no_notes": "Nenhuma observação foi fornecida com esta gravação.",
        "licence_lamp": "Lâmpada de placa ",
        "fift_wheel_grease": "Graxa para Quinta Roda",
        "electrical_connections_and_terminals": "Conexões Elétricas e Terminais",
        "dot_reflective_markings": "Marcas Reflexivas DOT",
        "access_ramps": "Rampas de Acesso",
        "brake_lamps_and_reflectors": "Lâmpadas de Freio e Refletores",
        "fasteners": "Fixadores",
        "ragged_sharp_edges_dammage_body_condition": "Bordas Afiadas e Desgastes na Carroceria",
        "trailer_sides_roof_damage": "Bordas Afiadas e Desgastes na Carroceria",
        "latches_gates_opening_devices": "Travas, Portões e Dispositivos de Abertura",
        "safety_kits": "Kits de segurança",
        "adr_kit": "Kits de ADR",
        "fridge_unit": "Unidade de Geladeira",
        "fridge_unit_fuels": "Combustíveis da Unidade de Geladeira",
        "straps": "Correias",
        "tailift_condition": "Condição do Elevador Traseiro",
        "tailift_operation": "Operação do Elevador Traseiro",
        "bumpers_guards": "Protetores de Para-choque",
        "additional_lamps": "Lâmpadas adicionais",
        "truck_and_trailer_cables_brake_connections": "Conexões dos cabos de freios",
        "air_suspension_leaks": "Vazamentos de Suspensão a ar",
        "spare_wheel_and_carrier": "Roda sobressalente e suporte",
        "hooses_and_pipes": "Mangueiras e Canos",
        "tyre_thread_depth": "Profundidade de Sulco",
        "tyre_condition": "Condição do Pneu",
        "tachograph": "Tacógrafo",
        "adblue_level_ok": "Nível de Arla-32 Ok",
        "steering": "Direção",
        "fuel_filter_cap": "Tampa do Tanque de Combustível",
        "cameras": "Câmeras",
        "load_secure_restraints": "Dispositivos de Segurança para Fixação de Carga",
        "doors": "Portas",
        "loading_bar": "Barra de carregamento",
        "three_tacho_rolls": "Três rolos de tacógrafo",
        "sack_truck": "Carrinho de mão",
        "wipes": "Limpadores",
        "first_aid_kit": "Kits de primeiro-socorros",
        "fire_extinguisher": "Extintor de incêndio",
        "breakdown_booklet": "Manual de Avarias",
        "incident_form": "Formulário de Incidente",
        "safety_check_sheet": "Ficha de Verificação de Segurança",
        "van_dimensions": "Dimensões da Van",
        "no_valuables_on_sight": "Sem Objetos de Valor à Vista",
        "fuel_on_return": "Combustível ao Retornar",
        "fuel_reciept": "Recibo de Combustível",
        "wheel_nut_indicators": "Indicadores de Porca de Roda",
        "wheel_torque_settings": "Configurações de Torque das Rodas",
        "attach_image": "Anexar Imagem",
        "media": "Mídia"
    },
    "mot_history": {
        "completed_date": "Data de conclusão",
        "expiry_date": "Data de Validade",
        "odometer_value": "Valor do Odômetro",
        "test_number": "Número do teste",
        "no_vehicle_found_error": "Nenhum teste MOT foi encontrado para este registro",
        "test_location": "Local do Teste",
        "major_defects": "Defeitos Graves",
        "first-mot-due": "Primeiro MOT Previsto",
        "date-registered": "Data de Registro"
    },
    "new_vehicle": {
        "add_vehicle": "Adicionar Veículo",
        "basic_information_title": "Informações Básicas",
        "basic_information_prompt": "Informações Básicas sobre o Veículo",
        "registration": "Registro",
        "check_dvla": "Verificar DVLA",
        "dvla_duplicate_vehicle": "Um veículo com este registro já existe",
        "manufacturer": "Fabricante",
        "model": "Modelo",
        "depot": "Depósito",
        "depots": "Depósitos",
        "fleets": "Frotas",
        "missing_sensor": "Sem sensor",
        "sensors": "Sensor",
        "engine_type": "Tipo de motor",
        "fuel_type": "Tipo de combustível",
        "map": "Mapa",
        "mot_due": "MOT Vencido",
        "chassis_type": "Tipo de chassi",
        "vehicle_type": "Tipo de veículo",
        "imei": "IMEI (Identificação Internacional de Equipamento Móvel)",
        "application": "Aplicação",
        "torque": "Torque",
        "sensor_information_title": "Informação do Sensor",
        "sensor_information_prompt": "Informações sobre a quantidade de sensores e seus tipos",
        "configuration": "Configuração",
        "sensor_manifest": "Manifesto de Sensores",
        "create_without_sensors": "Criar sem Sensores",
        "create_with_sensors": "Criar com Sensores",
        "list": "Veículos"
    },
    "vehicle_download": {
        "pressure": "Pressão do Pneu",
        "cold_pressure": "Pressão do Pneu á frio",
        "temperature": "Temperatura do Pneu",
        "select_all": "Selecionar todos",
        "deselect_all": "Deseleionar todos",
        "subheading": "Especifique os serviços e o período de tempo para o qual deseja baixar os dados. Os downloads podem levar algum tempo; você pode sair desta página assim que o download tiver começado, e o arquivo aparecerá na pasta de downloads assim que for concluído."
    },
    "manage_fleets": {
        "add_fleet_contact": "Adicionar contato para {fleet}",
        "contact_name": "Nome do contato",
        "add_contact": "Adicionar contato",
        "add_fleet": "Adicionar Frota",
        "total_fleets": "Frotas totais",
        "defaults": "Padrões",
        "defaults_prompt": "Esses valores serão usados como os limites padrão para todos os novos veículos criados nesta frota",
        "create_depot_prompt": "Adicionar depósitos para",
        "depots": "{ count } Depósito| { count } Depósitos",
        "created_on": "Criado em {date}",
        "add_depot": "Adicionar depósitos",
        "no_fleets": "Sem frotas",
        "no_depots": "Sem depósitos",
        "no_fleets_prompt": "Você atualmente não tem nenhuma frota em sua organização. Clique em \"Adicionar Frota\" para criar uma.",
        "no_depots_prompt": "Esta frota não contém nenhum depósito. Clique no botão adicionar depósito para criar um.",
        "duplicate_fleet_error": "Já existe uma frota com esse nome.",
        "duplicate_depot_error": "Já existe um depósito com esse nome.",
        "confirm_delete_depot_modal_text": "Tem certeza de que deseja excluir {depot}?",
        "confirm_delete_fleet_modal_text": "Tem certeza de que deseja excluir {fleet}?",
        "sensors_prompt": "Os seguintes veículos estão sem um ou mais sensores",
        "empty_prompt_header": "Todos os veículos estão configurados corretamente",
        "empty_prompt_subtitle": "Não há veículos nesta frota sem sensores",
        "subscribe": "Assinar",
        "unsubscribe": "Cancelar Assinatura",
        "subscribe_prompt": "Assinar para todos os veículos nesta frota",
        "unsubscribe_prompt": "Cancelar assinatura para todos os veículos nesta frota",
        "colour": "Cor",
        "definition": "Definição",
        "black_definition": "Nenhum dado",
        "blue_definition": "Nenhum dado nas últimas 12 horas",
        "green_definition": "Sem problemas",
        "yellow_definition": "Abaixo do limite de manutenção",
        "orange_definition": "Abaixo do limite de alerta",
        "red_definition": "Abaixo do limite de perigo",
        "subscribe_failed": "Falha ao assinar esta frota",
        "subscribe_success": "Assinatura bem-sucedida para todos os veículos desta frota",
        "unsubscribe_failed": "Falha ao cancelar a assinatura desta frota",
        "unsubscribe_success": "Cancelamento de assinatura bem-sucedido para todos os veículos desta frota",
        "delete_fleet": "Apagar Frota",
        "delete_failed": "Falha ao apagar esta frota",
        "update_depot": "Atualizar depósito",
        "show_map": "Exibir mapa",
        "hide_map": "Esconder mapa"
    },
    "sensors": {
        "add_sensor": "Adicionar sensor",
        "sensor_type": "Tipo de sensor",
        "bound": "Vínculo",
        "wheel_position": "Posição da Roda",
        "on_vehicle": "No Veículo",
        "not_on_vehicle": "Não no Veículo",
        "no_vehicle": "Sem Veículo",
        "updated_on": "Atualizado em",
        "no_wheel": "Sem Roda",
        "same_id_error": "Já existe um sensor com este ID ",
        "no_reg_error": "Não foi possível encontrar um veículo com esse registro",
        "axle_number": "Número do Eixo",
        "wheel_number": "Número da Roda",
        "no_data_recorded": "Nenhum dado registrado",
        "failed_to_load": "Falha ao carregar o sensor",
        "no_sensors": "Sem sensores",
        "no_sensors_subheading": "Você atualmente não possui nenhum sensor em sua organização. Clique em \"Adicionar Sensor\" para criar um.",
        "no_results_subheading": "Nenhum sensor foi encontrado que corresponda aos seus critérios de pesquisa.",
        "tyre_size": "Medida do Pneu",
        "tyre_make": "Fabricante do Pneu",
        "tyre_pattern": "Desenho do Pneu"
    },
    "reports": {
        "add_report": "Adicionar Relatório",
        "reports": "Relatórios",
        "add report": "Adicionar Relatório",
        "schedule": "Agenda",
        "send_now": "Enviar agora",
        "edit_report": "Editar Relatório",
        "emails": "Emails",
        "language": "Idioma",
        "ima": "Inspeção de pré manutenção (PMI)",
        "km": "Quilômetro (KM)",
        "co2": "Emissões (CO2)",
        "maintenance": "Manutenção",
        "vehicle_list": "Lista de Veículos",
        "basic_information": "Informações Básicas",
        "basic_information_prompt": "Selecionar qual tipo de relatório você deseja criar e enviar os detalhes requisitados",
        "frequency": "Frequência",
        "frequency_prompt": "Selecionar a frequência desejada para envio de relatórios",
        "day": "Dia",
        "daily": "Diariamente",
        "hour": "Hora",
        "failed_to_load": "Falha ao carregar relatório. Por favor tente novamente",
        "error_sending": "Ocorreu um erro ao enviar o relatório",
        "confirmation_send": "Relatório enviado com sucesso",
        "delete_error": "Ocorreu um erro ao excluir o relatório",
        "no_reports": "Sem Relatórios",
        "no_reports_subheading": "Você atualmente não possui nenhum relatório em sua organização. Clique em \"Adicionar Relatório\" para criar um"
    },
    "sim": {
        "add_sim": "Adicionar Cartão SIM",
        "search_iccid": "Procurar ICCID",
        "iccid_prompt": "O identificador exclusivo para o cartão SIM",
        "msisdn_prompt": "O número de telefone de 10 a 14 dígitos, incluindo o código do país (por exemplo, +55 no Brasil)",
        "provider": "Fornecedor",
        "send_sms": "Enviar SMS",
        "send_sms_prompt": "Enviar uma mensagem SMS para",
        "message": "Mensagem",
        "sms_sent": "SMS Enviado com sucesso",
        "imsi_description": "Número de Telefone da Unidade de Telemetria",
        "provider_description": "Provedor que emitiu o cartão SIM. Se o seu provedor de SIM não estiver listado, entre em contato com o suporte.",
        "unable_to_delete": "Não foi possível excluir o cartão SIM, entre em contato com o suporte.",
        "unable_to_edit": "Não foi possível editar o cartão SIM, entre em contato com o suporte.",
        "failed_to_create": "Falha ao criar o cartão SIM, verifique a configuração e tente novamente.",
        "no_sim_cards": "Sem Cartões SIM",
        "no_sim_cards_subheading": "Você atualmente não possui nenhum cartão SIM em sua organização. Clique em \"Adicionar Cartão SIM\" para criar um."
    },
    "telematics": {
        "edit_telematics": "Editar unidade de telemetria",
        "no_update_information": "Nenhuma informação de atualização",
        "add_telematics": "Adicionar unidade de telemetria",
        "search_imei": "Pesquisar IMEI (Identificação Internacional de Equipamento Móvel)",
        "go-to-vehicle": "Ir para o veículo",
        "software": "Software",
        "hardware": "Hardware",
        "main_port": "Porta Principal",
        "accessory_port": "Porta de acessório",
        "device_name": "Nome do Dispositivo",
        "remove": "Remover unidade de telemetria",
        "failed_to_load": "Falha ao carregar unidade de telemetira",
        "tyre-control-box-id": "ID da unidade receptora",
        "no_telematics_boxes": "Sem unidade de telemetria",
        "process-from-configurator": "Processo do configurador",
        "no_telematics_boxes_subheading": "Você atualmente não possui nenhuma caixa de telemetria em sua organização. Clique em \"Adicionar Unidade de Telemetria\" para criar uma.",
        "online": "Online",
        "offline": "Offline",
        "move-telematics-unit": "Mover unidade de telemetria"
    },
    "settings": {
        "profile": "Perfil ",
        "old_password": "Senha Antiga",
        "new_password": "Nova Senha",
        "confirm_password": "Confirmar Senha",
        "update_password": "Atualizar Senha",
        "profile_prompt": "Informações básicas sobre sua conta.",
        "password_prompt": "Mudar Senha",
        "first_name": "Primeiro Nome",
        "last_name": "Sobrenome",
        "password": "Senha",
        "locale": "Localidade",
        "locale_prompt": "Altere a forma como as informações são exibidas no site. O idioma e as unidades de temperatura selecionados aqui também serão aplicados nos relatórios, como os relatórios IMA",
        "language": "Idioma",
        "pressure_unit": "Unidade de Pressão",
        "temperature_unit": "Unidade de Temperatura",
        "time_zone": "Fuso Horário",
        "coming_soon": "Em breve",
        "update_settings": "Atualizar Configurações",
        "alerts": "Alertas",
        "no_alerts": "Sem  Alertas",
        "alerts_subtitle": "Você receberá alertas desses veículos.",
        "organisation_subtitle": "Configurações e informações relacionadas à sua organização.",
        "no_active_subscriptions_placeholder": "Você não possui assinaturas ativas. As assinaturas podem ser ativadas no menu do veículo.",
        "add_user": "Adicionar usuário",
        "username_error": "Esse nome de usuário ou email já está em uso.",
        "email_error": "Por favor, insira um endereço de email válido.",
        "current_organisation": "Atual (Padrão)",
        "confirm_delete_user_modal_text": "Tem certeza de que deseja excluir o usuário {user}?"
    },
    "not_found_page": {
        "title": "Página não encontrada",
        "subtitle": "Por favor, verifique a URL na barra de endereços e tente novamente",
        "return_home": "Retornar à Página Inicial"
    },
    "server_error_page": {
        "title": "Erro interno no servidor",
        "subtitle": "O servidor encontrou um erro e não pôde concluir sua solicitação.",
        "return_home": "Retornar à Página Inicial"
    },
    "application": {
        "logistics": "Logística",
        "mixed_service": "Serviço Misturado",
        "waste": "Disperdício",
        "food_distribution": "Distribuição de Alimentos",
        "food_waste": "Desperdício de Alimentos",
        "psv": "Veículo de Serviço Público",
        "off_highway": "Fora de estrada",
        "urban_last_mile": "Urbano / Última Milha",
        "other": "Outro"
    },
    "fuel": {
        "petrol": "Gasolina",
        "diesel": "Diesel",
        "electric": "Elétrico",
        "hybrid": "Hibrído",
        "biofuel": "Biocombustível",
        "hydrogen_fuel_cell": "Célula de combustível á hidrogênio",
        "compressed_natural_gas": "Gás natural comprimido",
        "liquified_petroleum_gas": "Gás liquefeito de petróleo",
        "non_applicable": "N/A"
    },
    "vehicle_type": {
        "bus": "Ônibus",
        "car": "Carro de Passeio",
        "crane": "Caminhão Guindaste",
        "digger": "Escavadeira",
        "dumper": "Caminhão Basculante",
        "loader": "Pá Carregadeira",
        "tractor": "Trator",
        "trailer": "Reboque",
        "truck": "Caminhão",
        "truck_trailer": "Cavalo mecânico com reboque",
        "tug": "Veículo de reboque",
        "van": "Van"
    },
    "tyre_condition": {
        "new": "Novo",
        "re-mould": "Recauchutado",
        "re-grooved": "Ressulcado"
    },
    "days": {
        "monday": "Segunda-feira",
        "tuesday": "Terça-feira",
        "wednesday": "Quarta-feira",
        "thursday": "Quinta-feira",
        "friday": "Sexta-feira",
        "saturday": "Sábado",
        "sunday": "Domingo"
    },
    "dvla": {
        "no_registration": "Por favor, insira um registro para verificar o DVLA",
        "failed_to_create": "Falha ao criar veículo",
        "not_found_error": "Veículo não encontrado"
    },
    "abbreviations": {
        "kilobytes": "KB"
    },
    "footer": {
        "powered_by_text": "Desenvolvido por RL Automotive"
    },
    "telematics-unit-vehicle-create-modal": {
        "imei-subtitle": "IMEI deve conter apenas números",
        "no-sim-card": "Sem Cartão SIM",
        "create-sim-card-subtitle": "Se não for possível encontrar o cartão SIM listado abaixo, tavlez seja necessário a criação de um novo",
        "no-matching-search": "Nenhum cartão SIM encontrado que corresponda aos critérios de pesquisa",
        "search-results-hidden": "Resultados de pesquisa adicionais estão ocultos"
    },
    "app-error-modal": {
        "extra-information": "Informação Extra"
    }
}
